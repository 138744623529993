<div
  ngbDropdown
  class="d-inline-block outerthing"
  #myDrop="ngbDropdown"
  container="body"
  (keydown.ArrowUp)="FocusOption(0); $event.stopPropagation()"
  (keydown.ArrowDown)="FocusOption(1); $event.stopPropagation()"
  (keydown.Enter)="SelectFocusedOption()"
  tabindex="0"
>
  <div class="thingy" #subcontainer>
    <div
      class="selected-container"
      (click)="
        FocusTop(1);
        multi ? input.nativeElement.focus() : '';
        $event.stopPropagation()
      "
      ngbDropdownAnchor
      id="dropdownManual"
    >
      <div class="selected-stuff-container" *ngIf="multi; else single">
        <div
          *ngIf="selected.length > 0"
          class="selected-items-container"
          #itemContainer
          [style.flex-wrap]="itemContainerWrap"
        >
          <div *ngFor="let val of selected">
            <div class="b-group">
              <div
                class="button-text-container"
                [ngClass]="{
                'remove-button-disabled': requiredValues.indexOf(val) !== -1,
              }"
              >
                <div class="button-text">
                  {{
                    selectService.labelMap[val] !== undefined
                      ? selectService.labelMap[val]
                      : '-----'
                  }}
                </div>
              </div>
              <button
                *ngIf="requiredValues.indexOf(val) === -1"
                class="remove-button"
                (click)="$event.stopPropagation(); removeMe(val, input)"
              >
                <nb-icon
                  class="closeIcon2"
                  icon="close-outline"
                  *ngIf="requiredValues.indexOf(val) === -1"
                ></nb-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- [style.width.px]='subcontainer.nativeElement.style.width.px - itemContainer.nativeElement.style.width.px - 30' -->
        <nb-form-field class="filter-container">
          <input
            #input
            type="text"
            nbInput
            fullWidth
            autocomplete="disabled"
            [value]="filterText"
            (keyup)="FilterOptions($event)"
            (keydown.Backspace)="DeleteSelected($event)"
            [placeholder]="placeholder"
          />

          <nb-checkbox
            nbSuffix
            [checked]="selectAllCheckbox"
            [indeterminate]="selectAllCheckboxIndeterminate"
            (checkedChange)="toggleSelectAllCheckbox($event)"
          >
          </nb-checkbox>
        </nb-form-field>
      </div>

      <ng-template #single>
        <input
          #input
          placeholder="{{
            selectService.labelMap[selected] !== undefined
              ? selectService.labelMap[selected]
              : placeholder
          }}"
          class="filter-input-single"
          [value]="filterText"
          (keyup)="FilterOptions($event)"
        />
      </ng-template>
    </div>
    <p
      *ngIf="selected?.length > 0 && multi && !noClearButton"
      class="clear-filters-link"
    >
      <span
        nbTooltip="Clear all selected filters"
        (click)="$event.stopPropagation(); myDrop.close(); ClearSelected()"
        >Clear selection</span
      >
    </p>
  </div>
  <div
    ngbDropdownMenu
    aria-labelledby="dropdownManual"
    style="background: none"
  >
    <resplendent-select-container
      style="width: 100%"
      #selectContainer
      *ngIf="this.myDrop.isOpen()"
    >
      <ng-content
        [select]="'ngx-select-group' || 'ngx-select-option'"
      ></ng-content>
    </resplendent-select-container>
  </div>
</div>
