import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@root/core-components/base-component';
import { takeUntil, tap } from 'rxjs/operators';
import { NgxSelectGroupComponent } from '../ngx-select-group/ngx-select-group.component';
import { NgxSelectOptionComponent } from '../ngx-select-option/ngx-select-option.component';
import { SelectService } from '../select.service';

@Component({
  selector: 'resplendent-select',
  templateUrl: './ngx-select.component.html',
  styleUrls: ['./ngx-select.component.scss'],
  providers: [SelectService],
})
export class NgxSelectComponent extends BaseComponent implements OnInit, OnChanges {
  @ContentChildren(NgxSelectOptionComponent, { descendants: true }) options: QueryList<NgxSelectOptionComponent>;
  @ContentChildren(NgxSelectGroupComponent, { descendants: true }) all_groups: QueryList<NgxSelectGroupComponent>;
  @ViewChild('myDrop') myDrop: NgbDropdown;
  @ViewChild('input') input: ElementRef;
  // @ViewChild('itemContainer') itemContainer: ElementRef;
  @ViewChild('subcontainer') subcontainer: ElementRef;
  @Input() placeholder: string = '';
  @Input() multi;
  @Input() noClearButton;
  @Input() requiredValues = [];
  alive = true;
  selectedVal;
  previous_value;
  @Input()
  get selected() {
    return this.selectedVal;
  }
  @Output() madeSelection = new EventEmitter();
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    this.selectedChange.emit(val);
    this.selectedVal = val;
  }

  selectAllCheckbox: boolean;
  selectAllCheckboxIndeterminate: boolean;

  filterText = '';

  counter = 0;
  size = 220;
  itemContainerWrap;
  constructor(private cdRef: ChangeDetectorRef, public selectService: SelectService) {
    super();
  }

  ngOnInit(): void {
    this.multi = this.multi !== undefined;
    this.noClearButton = this.noClearButton !== undefined;
    this.selectService.multi.next(this.multi);

    if (this.selected == undefined && this.multi) {
      this.selected = [];
    } else if (typeof this.selected == 'object' && !this.multi) {
      this.selected = null;
    } else if (typeof this.selected != 'object' && this.multi) {
      this.selected = [];
    }
    this.cdRef.detectChanges();
    this.selectService.selected
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((selected) => {
          this.madeSelection.emit({ value: selected, previous_value: this.previous_value });
          if (this.selectedVal != undefined) {
            this.previous_value = JSON.parse(JSON.stringify(this.selectedVal));
          }
          if (!this.multi) {
            this.selected = selected;
            this.myDrop.close();
            this.selectService.filter.next('');
            this.filterText = '';
            this.FocusTop();
          } else {
            if (selected.length == 1 && this.selected.length >= 1) {
              if (this.selected.indexOf(selected[0]) == -1) {
                this.selected.push(selected[0]);
                this.selectService.selected.next(this.selected);
              }
            } else {
              this.selected = selected;
            }
            this.manageSelectAllCheckbox();
            // this.sizeInput();
            // setTimeout(() => {
            //   this.sizeInput();
            // }, 50);
            this.FocusTop();
          }
          this.cdRef.detectChanges();
        }),
      )
      .subscribe();
    this.checkRequired();
  }

  ngOnChanges() {
    this.selectService.selected.next(this.selected);
    this.checkRequired();
  }
  checkRequired() {
    for (let req of this.requiredValues) {
      if (this.selected.indexOf(req) == -1) {
        this.selected = [...this.selected, req];
      }
    }
  }
  FilterOptions(event) {
    if (event.key != 'ArrowUp' && event.key != 'ArrowDown') {
      this.filterText = event.target.value.toLowerCase();
      this.selectService.filter.next(this.filterText);
      setTimeout(() => {
        this.FocusTop();
        // this.sizeInput();
      }, 50);
    }
  }

  ClearSelected() {
    if (this.multi) {
      this.selected = [...this.requiredValues];
    } else {
      this.selected = null;
    }
    this.selectService.selected.next(this.selected);
  }
  removeMe(value, input) {
    if (this.myDrop.isOpen()) {
      input.focus();
    }
    let i = this.selected.indexOf(value);
    if (i !== -1 && this.requiredValues.indexOf(value) === -1) {
      this.selected.splice(i, 1);
      this.selectService.selected.next(this.selected);
    }
  }
  DeleteSelected(event) {
    if (
      this.multi &&
      event.target.value == '' &&
      this.filterText == '' &&
      this.selected.length > 0 &&
      this.requiredValues.indexOf(this.selected[this.selected.length - 1]) === -1
    ) {
      this.selected.pop();
      this.selectService.selected.next(this.selected);
    }
  }

  FocusTop(open = 0) {
    if (this.all_groups !== undefined) {
      this.all_groups.forEach((g) => {
        g.hasFocus = false;
      });
    }
    if (this.options != undefined) {
      if (!this.myDrop.isOpen() || !open) {
        let isfirst = true;
        for (let c of this.options) {
          if (isfirst && c.FilterIncluded) {
            c.hasFocus = true;
            isfirst = false;
          } else {
            c.hasFocus = false;
          }
        }
      }
    }

    if (!this.myDrop.isOpen() && open) {
      this.myDrop.open();
    }
  }
  SelectFocusedOption() {
    this.options.forEach((opt) => {
      if (opt.hasFocus) {
        if (this.multi) {
          if (this.selected.indexOf(opt.value) == -1) {
            this.selected.push(opt.value);
          }
        } else {
          this.selected = opt.value;
        }
      }
    });
    this.selectService.selected.next(this.selected);
  }
  FocusOption(direction) {
    if (!this.myDrop.isOpen()) {
      this.myDrop.open();
    }
    let len = 0;
    let prev;
    let anyHasFocus = false;
    if (direction) {
      this.options.forEach((opt) => {
        if (opt.FilterIncluded) {
          len += 1;
          anyHasFocus = anyHasFocus || opt.hasFocus;
        }
        if (opt.hasFocus && prev != undefined && prev.hasFocus) {
          prev.hasFocus = false;
        }
        prev = opt;
      });
      if (!anyHasFocus) {
        this.FocusTop();
      }
    } else {
      let passedFocus = false;
      this.options.forEach((opt) => {
        if (opt.FilterIncluded) {
          len += 1;
        }
        if (passedFocus) {
          opt.hasFocus = false;
        }
        if (opt.hasFocus) {
          passedFocus = true;
        }
      });
    }
    this.all_groups.forEach((g) => {
      g.hasFocus = false;
    });

    prev = undefined;
    let first;
    let isfirst = true;
    let gotolast = false;
    let gotonext = false;
    let counter = 0;

    for (let opt of this.options) {
      if (opt.FilterIncluded) {
        if (isfirst) {
          first = opt;
        }

        if (opt.hasFocus) {
          opt.hasFocus = false;
          if (direction) {
            if (counter == len - 1) {
              first.hasFocus = true;
            } else {
              gotonext = true;
            }
          } else {
            if (counter == 0) {
              if (len == 1) {
                opt.hasFocus = true;
                break;
              } else {
                gotolast = true;
              }
            } else {
              prev.hasFocus = true;
              break;
            }
          }
        } else {
          if (gotolast && counter == len - 1) {
            opt.hasFocus = true;
            break;
          } else if (gotonext) {
            opt.hasFocus = true;
            break;
          }
        }

        prev = opt;
        isfirst = false;
        counter++;
      }
    }
  }
  manageSelectAllCheckbox() {
    if (!this.selected || this.selected.length === undefined || !this.options) return;
    if (this.selected.length === this.options.length || this.selected.length === 0) {
      this.selectAllCheckbox = this.selected.length === this.options.length;
      this.selectAllCheckboxIndeterminate = false;
    } else {
      this.selectAllCheckbox = false;
      this.selectAllCheckboxIndeterminate = true;
    }
  }
  toggleSelectAllCheckbox(checked: boolean) {
    if (checked) {
      this.selected.push(...this.options.map((o) => o.value).filter((v) => this.selected.indexOf(v) === -1));
      this.selectService.selected.next(this.selected);
    } else {
      this.ClearSelected();
    }
    this.manageSelectAllCheckbox();
  }
}
