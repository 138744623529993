import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { BaseComponent } from '@root/core-components/base-component';
import { takeUntil, tap } from 'rxjs/operators';
import { NgxSelectOptionComponent } from '../ngx-select-option/ngx-select-option.component';
import { SelectService } from '../select.service';

@Component({
  selector: 'resplendent-select-group',
  templateUrl: './ngx-select-group.component.html',
  styleUrls: ['./ngx-select-group.component.scss'],
})
export class NgxSelectGroupComponent extends BaseComponent implements OnInit {
  @ContentChildren(NgxSelectOptionComponent) options: QueryList<NgxSelectOptionComponent>;
  @ContentChildren(NgxSelectOptionComponent, { descendants: true }) all_options: QueryList<NgxSelectOptionComponent>;
  @ContentChildren(NgxSelectGroupComponent) groups: QueryList<NgxSelectGroupComponent>;
  @ContentChildren(NgxSelectGroupComponent, { descendants: true }) all_groups: QueryList<NgxSelectGroupComponent>;

  @Input() title;
  children_subscription;
  group_subscription;
  multiple;
  selected;
  ShowGroup = true;
  indent = 1;
  hasFocus = false;
  alive = true;
  constructor(public selectService: SelectService) {
    super();
  }

  ngOnInit(): void {
    this.isDestroyed$.subscribe(() => this.cleanUpOnDestroy());
    this.selectService.multi.pipe(tap((multi) => (this.multiple = multi)));
    this.subscribe();
  }

  cleanUpOnDestroy() {
    this.alive = false;
    if (this.children_subscription != undefined) {
      this.children_subscription.unsubscribe();
    }
    if (this.group_subscription != undefined) {
      this.group_subscription.unsubscribe();
    }
  }

  subscribe() {
    if (this.alive) {
      if (
        this.options != undefined &&
        this.groups != undefined &&
        this.all_options != undefined &&
        this.indent != undefined
      ) {
        this.groups.forEach((opt) => {
          opt.indent = this.indent + 1;
        });
        this.group_subscription = this.groups.changes.subscribe((c) => {
          this.groups.forEach((opt) => {
            opt.indent = this.indent + 1;
          });
        });

        this.options.forEach((opt) => {
          opt.indent = this.indent + 1;
        });
        this.children_subscription = this.options.changes.subscribe((options) => {
          let ShowGroup = false;
          this.options.forEach((opt) => {
            opt.indent = this.indent + 1;
            if (opt.FilterIncluded) {
              ShowGroup = true;
            }
          });
          this.ShowGroup = ShowGroup;
        });
        this.selectService.selected
          .pipe(
            tap((selected) => {
              this.selected = selected;
              setTimeout(() => {
                this.checkShowGroup();
              }, 100);
            }),
            takeUntil(this.isDestroyed$),
          )
          .subscribe();
        this.selectService.filter
          .pipe(
            tap(() => {
              setTimeout(() => {
                this.checkShowGroup();
              }, 100);
            }),
            takeUntil(this.isDestroyed$),
          )
          .subscribe();
        this.selectService.focus
          .pipe(
            tap(() => (this.hasFocus = false)),
            takeUntil(this.isDestroyed$),
          )
          .subscribe();
      } else {
        setTimeout(() => {
          this.subscribe();
        }, 200);
      }
    }
  }

  checkShowGroup() {
    if (this.multiple) {
      if (this.options != undefined && this.groups != undefined) {
        let ShowGroup = false;

        this.all_options.forEach((opt) => {
          if (opt.FilterIncluded) {
            ShowGroup = true;
          }
        });

        this.ShowGroup = ShowGroup;
      }
    }
  }
  SelectGroup() {
    if (this.multiple) {
      if (this.selected == undefined) {
        this.selected = [];
      }
      this.all_options.forEach((opt) => {
        if (this.selected.indexOf(opt.value) == -1) {
          this.selected.push(opt.value);
        }
      });
      this.selectService.selected.next(this.selected);
    }
  }
  focus() {
    if (this.multiple) {
      this.selectService.focus.next('');
      setTimeout(() => {
        this.hasFocus = true;
        this.all_options.forEach((opt) => {
          opt.hasFocus = true;
        });
        this.all_groups.forEach((g) => {
          g.hasFocus = true;
        });
      }, 50);
    }
  }
}
