<div [class]="'group-container ' + (ShowGroup ? '' : 'hidden')">
  <div
    class="simple-row {{ hasFocus ? 'focused' : '' }} {{ multiple ? 'pointer' : '' }}"
    (mouseenter)="focus()"
    (click)="SelectGroup()"
  >
    <h6 class="group-title  {{ indent !== 1 ? '' : 'groupthing ' }} " [style.margin-left.px]="10 * indent + 10">
      {{ title }}
    </h6>
    <nb-icon
      (click)="SelectGroup()"
      *ngIf="multiple; else singlething"
      class="groupSelectIcon"
      ngbPopover="Select Group"
      triggers="mouseenter:mouseleave"
      popoverClass="group-select-hint"
      icon="plus-square-outline"
    >
    </nb-icon>
    <ng-template #singlething>
      <div></div>
    </ng-template>
  </div>

  <ng-content [select]="'ngx-select-group' || 'ngx-select-option'"></ng-content>
</div>
