import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class SelectService {
  selected: BehaviorSubject<string | string[]> = new BehaviorSubject(null);
  multi: BehaviorSubject<boolean> = new BehaviorSubject(null);
  filter: BehaviorSubject<string> = new BehaviorSubject('');
  focus: BehaviorSubject<string> = new BehaviorSubject('');
  labelMap = {}
  constructor() {}
}
