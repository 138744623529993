import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@root/core-components/base-component';
import { takeUntil, tap } from 'rxjs/operators';
import { SelectService } from '../select.service';

@Component({
  selector: 'resplendent-select-option',
  templateUrl: './ngx-select-option.component.html',
  styleUrls: ['./ngx-select-option.component.scss'],
})
export class NgxSelectOptionComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('label') label_el: ElementRef<HTMLParagraphElement>;

  @Input() value;
  @Input() valueLabel;

  selected;
  multiple;
  indent = 1;

  IsSelected = false;
  FilterIncluded = true;
  hasFocus = false;
  label = '';
  filterText = '';

  constructor(public selectService: SelectService) {
    super();
  }

  ngOnInit(): void {
    this.selectService.multi
      .pipe(
        tap((multi) => {
          this.multiple = multi;
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe();
    this.selectService.selected
      .pipe(
        tap((selected) => {
          this.selected = selected;
          if (this.multiple) {
            this.IsSelected = this.selected.indexOf(this.value) != -1;
            this.FilterIncluded = !this.IsSelected && this.label.toLowerCase().includes(this.filterText);
          } else {
            this.IsSelected = this.selected === this.value;
          }
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe();
    this.selectService.filter
      .pipe(
        tap((filter) => {
          this.filterText = filter;
          this.FilterIncluded =
            this.label.toLowerCase().includes(this.filterText) && (!this.IsSelected || !this.multiple);
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe();
    this.selectService.focus
      .pipe(
        tap((value) => {
          this.hasFocus = this.value === value;
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.label = this.extractContent(this.label_el.nativeElement.outerHTML);
    this.selectService.labelMap[this.value] = this.label;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.label_el && !!this.label_el.nativeElement) {
      setTimeout(() => {
        this.label = this.extractContent(this.label_el.nativeElement.outerHTML);
        this.selectService.labelMap[this.value] = this.label;
      }, 10);
    }
  }
  /*
   * Gets the text of a paragraph
   */
  extractContent(s) {
    var p = document.createElement('p');
    p.innerHTML = s;
    return p.textContent || p.innerText;
  }

  selectMe() {
    if (this.IsSelected) {
      if (this.multiple) {
        let i = this.selected.indexOf(this.value);
        if (i != -1) {
          this.selected.splice(i, 1);
        }
        this.IsSelected = false;
        this.FilterIncluded = this.label.toLowerCase().includes(this.filterText);
      } else {
        this.selected = this.value;
      }
    } else {
      if (this.multiple) {
        if (this.selected == undefined) {
          this.selected = [];
        }
        this.selected.push(this.value);
        this.FilterIncluded = false;
      } else {
        this.selected = this.value;
      }
      this.IsSelected = true;
    }
    this.selectService.selected.next(this.selected);
  }
  focused() {
    this.hasFocus = true;
    this.selectService.focus.next(this.value);
  }
}
